// 导入axios实例
import httpRequest from "@/request/index"
import base from "@/request/base"
import qs from "qs"
import { getHeaderLang, getGameId } from "@/utils/util"
// 获取登录信息
export function gameLogin(params) {
  return httpRequest({
    // url: `${base.url}/web/login/account`,
    url: `${base.url}/login`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}
// 获取用户年龄
export function userAge(params) {
  return httpRequest({
    url: `${base.url}/user/age?official_setting_id=${params.official_setting_id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}
//获取游戏列表
export function getList() {
  return httpRequest({
    // url: `${base.url}/v3/pay/official/list`,
    url: `${base.url}/pay/official/list`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}
// 获取游戏列表
export function getGameList(params) {
  return httpRequest({
    // url: `${base.url}/v3/pay/official/list${qs.stringify(params)}`,
    url: `${base.url}/pay/official/list${qs.stringify(params)}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}
// 生成支付订单
export function GeneratingOrder(params) {
  return httpRequest({
    // url: `${base.url}/v3/pay/official/submit`,
    url: `${base.url}/pay/official/submit`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}
// 充值订单
export function order(params) {
  return httpRequest({
    url: `${base.url}/pay/official/order?${qs.stringify(params)}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}
// 生成支付web单
export function webPay(params) {
  return httpRequest({
    // url: `${base.url}/v3/pay/web`,
    url: `${base.url}/pay/web`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}
// 获取充值游戏角色
export function getRole(params) {
  return httpRequest({
    // url: `${base.url}/v3/pay/official/roles?os=${params.os}&game_id=${params.game_id}`,
    url: `${base.url}/pay/official/roles?os=${params.os}&game_id=${params.game_id}&official_setting_id=${params.official_setting_id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}
// 轮询查询订单
export function pcWechatType(params) {
  return httpRequest({
    url: `${base.url}/pay/official/order/${params.trade_no}?official_setting_id=${params.official_setting_id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}
// 游戏详情
export function gameDetail(game, role, official_setting_id) {
  return httpRequest({
    url: `${base.url}/pay/official/show/${game}?role_id=${role}&official_setting_id=${official_setting_id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}

// 支付前验证
export function payCheck(price, currency, official_setting_id) {
  return httpRequest({
    url: `${base.url}/pay/check?price=${price}&currency=${currency}&official_setting_id=${official_setting_id}`,
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}

// 补款支付调取
export function updateUserInfo(params) {
  return httpRequest({
    url: `${base.url}/user/update`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}
//  官方充值领取每日奖励
export function getReward(params) {
  return httpRequest({
    url: `${base.url}/pay/official/daily`,
    method: "post",
    data: qs.stringify(params),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Encrypt": "Origin",
      "X-Game-Id": getGameId(),
      "Accept-Language": getHeaderLang(),
      // 'X-Source': 'qcweb'
    },
  })
}
