//除法函数，用来得到精确的除法结果
//说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
//调用：accDiv(arg1,arg2)
//返回值：arg1除以arg2的精确结果
export function accDiv(arg1, arg2) {
  var t1 = 0,
    t2 = 0,
    r1,
    r2
  try {
    t1 = arg1.toString().split(".")[1]?.length ?? 0
  } catch (e) {
    console.log(e)
  }
  try {
    t2 = arg2.toString().split(".")[1]?.length ?? 0
  } catch (e) {
    console.log(e)
  }
  r1 = Number(arg1.toString().replace(".", ""))
  r2 = Number(arg2.toString().replace(".", ""))
  if (r2 === 0) {
    return Infinity
  } else {
    return accMul(r1 / r2, Math.pow(10, t2 - t1))
  }
}

// 乘法精度丢失处理
export function accMul(arg1, arg2) {
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString()
  try {
    m += s1.split(".")[1]?.length ?? 0
  } catch (e) {
    console.log(e)
  }
  try {
    m += s2.split(".")[1]?.length ?? 0
  } catch (e) {
    console.log(e)
  }
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  )
}

export function isNumber(value) {
  return !isNaN(parseFloat(value)) && isFinite(value)
}

export function toThousands(num) {
  // var result = [], counter = 0;
  // num = (num || 0).toString().split('')
  // for (var i = num.length - 1; i >= 0; i--) {
  //   counter++;
  //   result.unshift(num[i]);
  //   if (!(counter % 3) && i != 0) { result.unshift(','); }
  // }
  // return result.join('');
  return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
}

// 设置本地缓存语言
export function setLang() {
  let type = "thTH"
  if (localStorage.getItem("official_setting_id")) {
    type = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
  }
  const langMap = {
    "pay.qcplay.co.jp": "jaJP",
    "pay.qcplay.com": "en",
    "pay.barleyplay.com": type,
    "pay.snell.com.tw": "zhTW",
    "pay.supersnail.vn": "viVN",
    "localsnell.com.tw": "zhTW",
  }
  for (let i in langMap) {
    if (window.location.href.includes(i)) {
      window.localStorage.setItem("lang", langMap[i])
    }
  }
}

// 获取当前语言
export function getLang() {
  let type = "thTH"
  if (localStorage.getItem("official_setting_id")) {
    type = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
  }
  const langMap = {
    "pay.qcplay.co.jp": "jaJP",
    "pay.qcplay.com": "en",
    "pay.barleyplay.com": type,
    "pay.snell.com.tw": "zhTW",
    "pay.supersnail.vn": "viVN",
    "localsnell.com.tw": "zhTW",
  }
  let lang = "en"
  for (let i in langMap) {
    if (window.location.href.includes(i)) {
      lang = langMap[i]
    }
  }
  return lang
}

export function getHeaderLang() {
  let type = "th-th"
  const officialSettingId = localStorage.getItem("official_setting_id")
  const langList = {
    en: "en-us",
    zhCN: "zh-cn",
    idID: "id-id",
    viVN: "vi-vn",
  }

  // 当官方设置ID为5时，根据语言设置type
  if (officialSettingId == 5) {
    const lang = localStorage.getItem("lang")
    if (langList[lang]) {
      type = langList[lang]
    }
  }

  // 当官方设置ID为6时，直接设置type为'vi-vn'
  if (officialSettingId == 6) {
    type = "vi-vn"
  }

  const langMap = {
    "pay.qcplay.co.jp": "ja-jp",
    "pay.qcplay.com": "en-us",
    "pay.barleyplay.com": type,
    "pay.snell.com.tw": "zh-tw",
    "pay.supersnail.vn": "vi-vn",
    "localsnell.com.tw": "zh-tw",
  }
  let lang = "en-us"
  for (let i in langMap) {
    if (window.location.href.includes(i)) {
      lang = langMap[i]
      break
    }
  }
  return lang
}

// 获取当前语言对应的游戏ID
export function getGameId() {
  let type = localStorage.getItem("official_setting_id") ? 57 : 64
  const gameIdMap = {
    "ja-jp": 51,
    "en-us": type,
    "th-th": localStorage.getItem("gameId") || 57,
    "zh-tw": localStorage.getItem("gameId") || 60,
    "vi-vn": 57,
    "zh-cn": 57,
    "id-id": 57,
  }
  return gameIdMap[getHeaderLang()]
}

// 支付方式对应的映射
export function getPayType(type) {
  const typeMap = {
    kzyh: "airWallex",
    credit: "mulPay",
    docomo: "mulPay",
    au: "mulPay",
    softbank: "mulPay",
    visa: "xsolla",
    pp: "xsolla",
    xsolla: "xsolla",
    unipin: "uniPin",
    payerMax: "payerMax",
    myCard: "myCard",
    uPay: "uPay",
    PayPay: "PayPay",
  }
  return typeMap[type]
}
