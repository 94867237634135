import HeaderContainer from "@/components/HeaderContainer/HeaderContainer.vue";
import LoginPage from "@/components/LoginPage/LoginPage.vue";
import { ref, onMounted } from "vue";
import bus from "./lib/bus";
import { useI18n } from "vue-i18n";
import { getLang } from "@/utils/util";
export default {
  name: "App",
  components: {
    HeaderContainer,
    LoginPage
  },
  setup() {
    // 登录弹窗
    const show = ref(false);
    const showLogins = () => {
      show.value = true;
      bus.$emit("changeLang"); // 改变登陆框内的语言
    };
    const closeLoginContainer = () => {
      show.value = false;
    };
    const listenClose = () => {
      bus.$emit("scrollGoToTop");
    };
    // 设置头部标题
    const changeTitle = lang => {
      const titleMap = {
        en: "QCPlay | QCPLAY PAYMENT CENTER",
        jaJP: "QCPlay | QCPlayトークンストア",
        thTH: "QCPlay | ศูนย์เติมเงินทางการของ QCPLAY",
        zhTW: "Snell Game官方儲值中心",
        viVN: "Trang nạp tiền chính thức của VISION NETWORK",
        zhCN: "青瓷游戏官方充值中心",
        idID: "Pusat Top Up Resmi QCPlay"
      };
      document.title = titleMap[lang];
    };
    // 登录成功
    const token = ref("");
    const sendToken = e => {
      token.value = e;
      show.value = false;
    };
    // const route = useRouter()
    const {
      locale
    } = useI18n();
    onMounted(() => {
      setTimeout(() => {
        const lang = getLang();
        locale.value = lang;
        if (lang === "thTH" || lang === "viVN" || lang === "en" || lang === "zhCN" || lang === "idID") {
          var initConfig = {
            appKey: "QCPLAY_app_7f3698cf30484210a1568e1480b73692",
            domain: "qcplay.aihelp.net",
            appId: "qcplay_platform_5c759b24784857a4c0825cd965c009eb",
            language: "en",
            supportMode: "showConversation"
          };
          if (typeof AIHelpSupport !== undefined) window.AIHelpSupport.init(initConfig);
        }
        changeTitle(lang);
      }, 500);
    });
    // 调取登录弹窗
    bus.$on("click", () => {
      showLogins();
    });
    return {
      listenClose,
      token,
      show,
      showLogins,
      closeLoginContainer,
      sendToken,
      changeTitle
    };
  }
};